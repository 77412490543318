import moment from "moment";

export const sortEvent = (calendarData) => {
    let users = [];

    calendarData.data.forEach(user => {
        if (users[user.id] === undefined) {
            user.calendarEvents = [];
            user.tasks.forEach(task => {
                user.calendarEvents.push({
                    key: user.name + "_task_" + task.id,
                    id: task.id,
                    userId: user.id,
                    title: user.name,
                    client: task.client,
                    facility: task.facility,
                    security_systems: task.security_systems,
                    start: moment.utc(task.service_from).toDate(),
                    end: moment.utc(task.service_to).toDate(),
                    serviceType: task.service_type,
                    assignees: task.assignees,
                    important: task.important,
                    status: task.status,
                    clientContact: {
                        name: task.client_contact_name_report,
                        phone: task.client_contact_phone_report
                    },
                    type: "task",
                })
            })

            user.excpetions.forEach(exception => {
                user.calendarEvents.push({
                    key: user.name + "_exceptions_" + exception.id,
                    id: exception.id,
                    userId: user.id,
                    exception: exception.exception,
                    exception_name: exception.name,
                    title: user.name,
                    start: moment.utc(exception.from).toDate(),
                    end: moment.utc(exception.to).toDate(),
                    type: "exception"
                })
            })

            users[user.id] = user;
        }
    });

    calendarData.events.forEach(event => {
        users[event.technician[0].id].calendarEvents.push({
            key: event.technician[0].title + "_event_" + event.id,
            id: event.id,
            userId: event.technician[0].id,
            title: event.technician[0].title,
            start: moment.utc(event.from).toDate(),
            end: moment.utc(event.to).toDate(),
            project_number: event.project_number,
            facility: event.facility[0],
            type: 'event'
        })
    });

    return users;
}