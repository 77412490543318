import * as dates from 'date-arithmetic'

export const isNull = (value) => {
    if (value === undefined || value === null) return true;
    return false;
};

export const parseJwt = (token) => {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export const displayDate = (date, type, withSeconds) => {
    const newDate = new Date(date);

    const year = newDate.getFullYear()+'';
    let month = newDate.getMonth() + 1+'';
    let day = newDate.getDate()+'';
    let hours = newDate.getHours()+'';
    let minutes = newDate.getMinutes()+'';
    let seconds = newDate.getSeconds()+''

    if (month.length === 1) month = '0' + month;
    if (day.length === 1) day = '0' + day;
    if (hours.length === 1) hours = '0' + hours;
    if (minutes.length === 1) minutes = '0' + minutes;
    if (seconds.length === 1) seconds = '0' + seconds;


    if (type === 'date') {
        return day + "." + month + "." + year;
    } else if (type === 'time') {
        return hours + ":" + minutes + (withSeconds ? (":" + seconds) : "");
    } else {
        return day + "." + month + "." + year + " " + hours + ":" + minutes + (withSeconds ? (":" + seconds) : "");
    }
}

export const dateFormatForBE = (date, showTime) => {
    if (!isNull(date) && date.length !== 0) {
        const dateTime = date.split('T');
        return  dateTime[0] + (showTime ? ' ' + dateTime[1].replace('Z','') : '');
    } else {
        return null;
    }
}

export const bytesToSize = (bytes) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

export const customStyles = {
    rows: {
        style: {
            minHeight: '60px',
        },
    },
    headCells: {
        style: {
            backgroundColor: 'var(--theme-secondary)',
            color: '#ffffff'
        },
    },
    cells: {
        style: {
            color: '#000',
        },
    },
};

export const number_format = (number, decimals, dec_point, thousands_sep) => {
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    let n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        toFixedFix = function (n, prec) {
            let k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    let s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}

export const modalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      padding: '0px',
      overflow: 'initial',
      transform: 'translate(-50%, -50%)',
      width: '450px',
      maxWidth: '95%',
      maxHeight: '90vh',
      borderRadius: '12px',
      boxShadow: '0px 0px 34px rgba(0, 0, 0, 0.28)',
      zIndex: '50',
    },
};

export const paginationComponentOptions = (t) => {
    return {
        rowsPerPageText: t('table.pagination.show'),
        rangeSeparatorText: t('table.pagination.from'),
    }
};

export const getNavigateNumbers = (date) => {
    let currentDate = new Date(date);
    let startDate = new Date(currentDate.getFullYear() - 1, 11, 29);

    if (dates.gte(currentDate, new Date(currentDate.getFullYear(), 11, 29), 'day')) {
        return {week: 1, year: new Date(date).getFullYear() + 1};
    } else {
        let days = /*Math.floor*/((currentDate - startDate) / (24 * 60 * 60 * 1000));
        const weekNumber = Math.ceil(days / 7);
        return {week: weekNumber <= 0 ? 1 : weekNumber, year: new Date(date).getFullYear()};
    }
}

export const rangeFunc = (start, end, unit = 'day') => {
    let current = start
    const days = []
    while (dates.lte(current, end, 'hour')) {
        days.push(current)
        current = dates.add(current, 1, unit)
    }
    return days
}

export const isInRange = (date, rangeStart, rangeEnd, unit = 'day') => {
    return dates.inRange(date, rangeStart, rangeEnd, unit)
}

export const getTimezoneOffset = () => {
    return (new Date().getTimezoneOffset() / 60)*-1;
}

export const fieldsWithCheckboxes = ['working_hour_price', 'overtime_price', 'night_overtime_price', 'sunday_overtime_price', 'sunday_night_overtime_price', 'travel_price', 'intervention_price', 'programming_price', 'advisory_price', 'mileage_price', 'active_to'];

export const  getRandomInt = (max) => {
    return Math.floor(Math.random() * max);
}

export const replaceSpecialChars = (string) => {
    const map = {
        'ž': 'z',
        'š': 's',
        'č': 'c',
        'ć': 'c'
    };

    return string.replace(/[žščć]/g, function(matched) {
        return map[matched];
    });
}